import StatusLabel from '@/common/components/StatusLabel';
import { RequestStatus, WorkOrderStatus } from '@/graphql/types';
import { useWorkOrderStatus } from '@/modules/workOrders/hooks/useWorkOrderStatus';
import { FC } from 'react';
import { useRequestStatus } from '../hooks/useRequestStatus';

type RequestStatusLabelProps = {
  status: RequestStatus;
  workOrderStatus?: WorkOrderStatus;
};

const RequestStatusLabel: FC<RequestStatusLabelProps> = (props: RequestStatusLabelProps) => {
  const { status, workOrderStatus } = props;

  const { getStatusAppearanceInfo: getWorkOrderStatusAppearanceInfo } = useWorkOrderStatus();
  const { getStatusAppearanceInfo: getRequestStatusAppearanceInfo } = useRequestStatus();

  const appearanceInfo = workOrderStatus
    ? getWorkOrderStatusAppearanceInfo(workOrderStatus)
    : getRequestStatusAppearanceInfo(status);

  return <StatusLabel status={appearanceInfo} testId='request-status-label' />;
};

export default RequestStatusLabel;
